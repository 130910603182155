@import '~antd/dist/antd.css';
.App {
  text-align: left;
}

body, html {
  font-family: "Arial", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
} */

.ant-layout-header {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
}

.ant-layout-content {
  padding: 0;
  margin: 0;
}

ul.ant-menu-horizontal {
  flex: 1;
  margin-left: 24px;
}

.site-layout {
  padding: 0 16px;
}

.site-layout-content {
  min-height: 280px;
  /* padding: 0 24px; */
  /* background: #fff; */
}
.logo {
  padding-top: 24px;
  padding-left: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
}
.logo img {
  /* float: left; */
  /* width: 120px; */
  height: 32px;
  /* margin: 16px 24px 16px 0; */
}

.logo span {
  display: block;
  color: #fffa;
  font-size: 16px;
  margin-left: 12px;
}

.logo span:nth-child(2) {
  color: #fff6;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.bg1 {
  background-color: yellow;
}

.bg2 {
  background-color: yellowgreen;
}

.bg3 {
  background-color: oldlace;
}

.bg-white {
  background-color: #fff;
  padding: 16px;
  margin-bottom: 24px;
}

.elem-page {
  min-height: 85vh;
}